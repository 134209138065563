import React, { useState } from 'react';
import { useTranslation } from '@/presentation/hooks/useTranslation';
import { LoginComponentSourceName } from '@/presentation/components/Authentication/types';
import LoginSideBarEnhanced from '@/presentation/components/LoginSideBarEnhanced';
import { Community } from '@/domain/entities/Communities';
import {
  isNewFeedPaginationEnabled,
  isShowCommunitiesEnabled,
} from '@/presentation/services/featureFlags';
import { useRouter } from 'next/router';
import { CommentedOffer, FeedDeal } from '@/domain/entities/Offer';
import { VoteVariables } from '@/infra/api/votes/types/Vote';
import { RemoveVoteVariables } from '@/infra/api/votes/types/RemoveVote';
import { FeaturedStores } from '@/domain/entities/Stores';
import { LoggedUser } from '@/domain/entities/User';
import { FilterOptions } from '@/domain/entities/Filter';
import { SpecialFeedTab } from '../../../services/feedTabsCategories';
import ExploreFilters, { ExploreTab } from './components/ExploreFeedTabs';
import FeedTabs from './components/SpecialFeedTabs';
import FeedContent from '../../../components/Feed/FeedContent';
import { SlotCode } from '../../../components/AdPlacement/constants';
import { useFeedCardsAds } from './hooks/useFeedCardsAds';
import FeaturedStoresCarousel from './components/FeaturedStoresCarousel';
import {
  ExploreCategoryContainer,
  Content,
  PageContainer,
  FeedTabsContainer,
  AdSidebar,
  ContentWrapper,
  ExploreCategoryTitle,
  SporsorshipAd,
  AsideAd,
  GreetingsContainer,
  AdContainer,
} from './style';
import CommunityFeedHeader from './components/CommunityFeedHeader';
import FeedLoginBannerEnhanced from './components/FeedLoginBannerEnhanced';

export type DealsListContent<Deals extends FeedDeal = FeedDeal> = {
  deals: Deals[];
  isLoading?: boolean;
  isPaginationLoading?: boolean;
  hasNextPage?: boolean;
  totalPages?: number;
  refetch?: () => void;
  fetchMore: () => void;
};

export type ChangeFilterOptions = Partial<FilterOptions>;

export type FeedPageProps = {
  onUpdateActiveFeedTab: (tab: SpecialFeedTab) => void;
  initialTab: SpecialFeedTab;
  initialFilters: FilterOptions;
  onChangeFilter: (
    filter: ChangeFilterOptions,
    exploreTab?: ExploreTab
  ) => void;
  refetchFilterContent: (filter: ChangeFilterOptions) => void;
  categorySlug: string;
  exploreCategoryTitle?: string;
  exploreContent?: DealsListContent;
  featuredContent: DealsListContent;
  recentsContent: DealsListContent;
  commentedContent: DealsListContent<CommentedOffer>;
  hottestContent: DealsListContent;
  featuredStores?: FeaturedStores[];
  onVote: (variables: VoteVariables) => void;
  onRemoveVote: (variables: RemoveVoteVariables) => void;
  currentUser?: LoggedUser;
  community?: Community;
  googleClientId?: string;
  initialPage?: number;
};

export const TABS_CONTAINER_ID = 'feed-tabs-container';

function FeedPage({
  initialTab,
  onUpdateActiveFeedTab,
  exploreCategoryTitle,
  exploreContent,
  featuredContent,
  recentsContent,
  commentedContent,
  hottestContent,
  initialFilters,
  onChangeFilter,
  refetchFilterContent,
  categorySlug,
  featuredStores,
  onVote,
  onRemoveVote,
  currentUser,
  community,
  googleClientId,
  initialPage,
}: FeedPageProps) {
  const { locale } = useRouter();
  const [isShowingPagination, setIsShowingPagination] = useState(false);
  const { t } = useTranslation('feed');
  const feedAds = useFeedCardsAds();

  const featuredSlug = t('slug-featured');
  const commentedSlug = t('slug-commented');
  const recentSlug = t('slug-recent');
  const hottestSlug = t('slug-hottest');

  const isLogged = !!currentUser;

  const showGreetings = !isLogged && !exploreCategoryTitle && !community;

  const isShowCommunitiesFlagEnabled = isShowCommunitiesEnabled();

  const isCommunityFeed =
    isShowCommunitiesFlagEnabled && community && !exploreCategoryTitle;

  const isFeedPaginationEnabled = isNewFeedPaginationEnabled();

  return (
    <>
      {isCommunityFeed ? (
        <CommunityFeedHeader community={community} />
      ) : (
        exploreCategoryTitle && (
          <ExploreCategoryContainer>
            <ExploreCategoryTitle className="maximumGrid">
              {exploreCategoryTitle}
            </ExploreCategoryTitle>
          </ExploreCategoryContainer>
        )
      )}
      <FeedLoginBannerEnhanced
        googleClientId={googleClientId}
        isShowingPagination={isShowingPagination && isFeedPaginationEnabled}
      />
      <PageContainer
        className="maximumGrid"
        data-is-community-feed={isCommunityFeed}
      >
        <ContentWrapper>
          <GreetingsContainer>
            {showGreetings && (
              <FeaturedStoresCarousel
                featuredStores={featuredStores}
                locale={locale}
              />
            )}
          </GreetingsContainer>

          <SporsorshipAd
            slotCode={SlotCode.FEED_SPONSORSHIP_01}
            data-testid={SlotCode.FEED_SPONSORSHIP_01}
          />
          <Content>
            <FeedTabsContainer id={TABS_CONTAINER_ID}>
              {exploreCategoryTitle || isCommunityFeed ? (
                <ExploreFilters
                  initialFilters={initialFilters}
                  onChangeFilter={onChangeFilter}
                  refetchFilterContent={refetchFilterContent}
                  exploreContent={exploreContent}
                  categorySlug={categorySlug}
                  onVote={onVote}
                  onRemoveVote={onRemoveVote}
                  insertContentAt={feedAds}
                  currentUser={currentUser}
                  isCommunityFeed={isCommunityFeed}
                  initialPage={initialPage}
                  setIsShowingPagination={setIsShowingPagination}
                  googleClientId={googleClientId}
                />
              ) : (
                <FeedTabs
                  initialTab={initialTab}
                  onChangeTab={onUpdateActiveFeedTab}
                  initialFilters={initialFilters}
                  onChangeFilter={onChangeFilter}
                  isCommunityFeed={isCommunityFeed}
                  featuredTabContent={
                    <FeedContent
                      key={featuredSlug}
                      content={featuredContent}
                      categorySlug={featuredSlug}
                      categoryName={t('tab-featured')}
                      onVote={onVote}
                      onRemoveVote={onRemoveVote}
                      insertContentAt={feedAds}
                      currentUser={currentUser}
                      googleClientId={googleClientId}
                    />
                  }
                  recentsTabContent={
                    <FeedContent
                      key={recentSlug}
                      content={recentsContent}
                      categorySlug={recentSlug}
                      categoryName={t('tab-recents')}
                      onVote={onVote}
                      onRemoveVote={onRemoveVote}
                      insertContentAt={feedAds}
                      currentUser={currentUser}
                      initialPage={initialPage}
                      setIsShowingPagination={setIsShowingPagination}
                      hasPagination={isFeedPaginationEnabled}
                      googleClientId={googleClientId}
                    />
                  }
                  commentedTabContent={
                    <FeedContent
                      key={commentedSlug}
                      content={commentedContent}
                      categorySlug={commentedSlug}
                      categoryName={t('tab-comments')}
                      onVote={onVote}
                      onRemoveVote={onRemoveVote}
                      insertContentAt={feedAds}
                      currentUser={currentUser}
                      initialPage={initialPage}
                      setIsShowingPagination={setIsShowingPagination}
                      hasPagination={isFeedPaginationEnabled}
                      googleClientId={googleClientId}
                    />
                  }
                  hottestTabContent={
                    <FeedContent
                      key={hottestSlug}
                      content={hottestContent}
                      categorySlug={hottestSlug}
                      categoryName={t('tab-hottest')}
                      onVote={onVote}
                      onRemoveVote={onRemoveVote}
                      insertContentAt={feedAds}
                      currentUser={currentUser}
                      initialPage={initialPage}
                      setIsShowingPagination={setIsShowingPagination}
                      hasPagination={isFeedPaginationEnabled}
                      googleClientId={googleClientId}
                    />
                  }
                />
              )}
            </FeedTabsContainer>
            <AdSidebar>
              <LoginSideBarEnhanced
                googleClientId={googleClientId}
                componentSourceName={LoginComponentSourceName.sideBarAuthBanner}
                isLogged={isLogged}
              />
              <AdContainer>
                <AsideAd
                  slotCode={SlotCode.FEED_MEDIUM_RECTANGLE}
                  data-testid={SlotCode.FEED_MEDIUM_RECTANGLE}
                />
              </AdContainer>
            </AdSidebar>
          </Content>
        </ContentWrapper>
      </PageContainer>
    </>
  );
}

export default FeedPage;
