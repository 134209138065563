import styled, { css, keyframes } from 'styled-components';
import {
  H1Heavy,
  H3Heavy,
  MediaQuery,
  ThemeName,
  THEME_BODY_ATTR,
  Theme,
} from '@pelando/components';
import Image from 'next/image';
import { HEADER_HEIGHT } from '../../../components/Header/styles';
import { AdPlacement } from '../../../components/AdPlacement';

export const DarkThemeQuery = `body[${THEME_BODY_ATTR}='${ThemeName.DARK}' i] &`;
export const LightThemeQuery = `body[${THEME_BODY_ATTR}='${ThemeName.LIGHT}' i] &, body:not([${THEME_BODY_ATTR}]) &`;
export const DarkNinjaThemeQuery = `body[${THEME_BODY_ATTR}='${ThemeName.DARK_NINJA}' i] &`;
export const LightNinjaThemeQuery = `body[${THEME_BODY_ATTR}='${ThemeName.LIGHT_NINJA}' i] &, body:not([${THEME_BODY_ATTR}]) &`;

const skeletonFadeKeyframes = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0.9;
  }
`;

const skeletonAnimation = css`
  animation: 0.75s ${skeletonFadeKeyframes} alternate infinite;
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  &[data-is-community-feed='true'] {
    background-color: rgb(${({ theme }) => theme.colors.Juliet});

    @media ${MediaQuery.SCREEN_MD_UP} {
      background-color: unset;
    }
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ExploreCategoryContainer = styled.div`
  display: flex;
  align-items: center;
  height: 52px;
  width: 100%;
  padding: 16px 0 12px;
  background-color: rgb(${({ theme }) => theme.colors.Juliet});
  border-bottom: 1px solid rgb(${({ theme }) => theme.colors.Golf});

  @media ${MediaQuery.SCREEN_MD_UP} {
    height: 92px;
    padding: 24px 0 32px;
  }
`;

export const ExploreCategoryTitle = styled.h2`
  ${H3Heavy}
  width: 100%;
  margin-right: auto;
  margin-left: auto;

  @media ${MediaQuery.SCREEN_MD_UP} {
    ${H1Heavy}
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: calc(100vw - 32px);

  @media ${MediaQuery.SCREEN_LG_UP} {
    display: grid;
    grid-template-columns: minmax(100px, 1fr) auto;
  }
`;

export const FeedTabsContainer = styled.div`
  width: 100%;
  margin-bottom: 96px;
  scroll-margin-top: 72px;
  @media ${MediaQuery.SCREEN_MD_UP} {
    padding-right: 16px;
  }
`;

export const AdSidebar = styled.aside`
  display: none;
  width: 300px;
  margin-top: 72px;
  border-radius: 16px;
  @media ${MediaQuery.SCREEN_LG_UP} {
    display: flex;
    flex-direction: column;
  }
`;

export const AdContainer = styled.aside`
  position: sticky;
  top: ${HEADER_HEIGHT + 72}px;
  height: 50vh;
  max-height: 600px;
  border-radius: 16px;
`;

export const AsideAd = styled(AdPlacement)`
  border-radius: 8px;

  background: ${`rgb(${Theme.colors.Golf})`};
  height: 600px;
`;

export const AdBannerContainer = styled.div`
  position: relative;
  cursor: pointer;
  display: none;
  width: 100%;
  height: 148px;
  margin-top: 16px;
  border-radius: 16px;
  @media ${MediaQuery.SCREEN_LG_UP} {
    display: flex;
  }
`;

export const AdBannerImage = styled(Image)`
  z-index: 0;
`;

export const FeedListAdCard = styled(AdPlacement)`
  margin: 24px auto;
  min-height: 90px;
  border-radius: 8px;

  background: ${`rgb(${Theme.colors.Golf})`};
`;

export const SporsorshipAd = styled(AdPlacement)`
  min-height: 96px;
  margin-top: 16px;
  border-radius: 8px;
  animation: ${skeletonAnimation};

  background: ${`rgb(${Theme.colors.Golf})`};

  @media (min-width: 360px) {
    min-height: 109px;
  }

  @media (min-width: 412px) {
    min-height: 127px;
  }

  @media (min-width: 736px) {
    min-height: 92px;
  }

  @media (min-width: 1366px) {
    min-height: 163px;
  }
`;

export const GreetingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  &[data-hidden='true'] {
    display: none;
  }
`;
