import { Button, MediaQuery } from '@pelando/components';
import styled from 'styled-components';

export const DealOnboardingContinueButton = styled(Button)`
  && {
    display: flex;
    position: fixed;
    bottom: 36px;
    min-width: 325px;
    width: 90%;
    height: 48px;

    @media ${MediaQuery.SCREEN_MD_UP} {
      position: absolute;
      max-width: 325px;
      bottom: 82px;
    }
  }
`;
