import { Colors, MediaQuery, ProgressBar, Theme } from '@pelando/components';
import styled from 'styled-components';
import { StepProgressBar } from '../../StepProgressBar';
import { DealStepTypes } from '../consts';

type ProgressBarProps = {
  animate?: boolean;
  stepType?: DealStepTypes;
};

export const StepsModalProgressBarDeal = styled(
  StepProgressBar
)<ProgressBarProps>`
  display: none;
  position: absolute;
  bottom: 48px;

  span {
    background-color: rgb(
      ${({ stepType }) =>
        stepType === DealStepTypes.WELCOME_STEP
          ? Colors.White
          : Theme.colors.Brand}
    );
    opacity: 0.3;

    &[aria-pressed='true'] {
      opacity: 1;
      background-color: rgb(
        ${({ stepType }) =>
          stepType === DealStepTypes.WELCOME_STEP
            ? Colors.White
            : Theme.colors.Brand}
      );
    }
  }

  @media ${MediaQuery.SCREEN_MD_UP} {
    display: flex;
    bottom: 60px;
  }
`;

export const MobileProgressBarDealContainer = styled.div`
  display: flex;
  position: fixed;
  justify-content: center;
  width: 80px;
  z-index: 3;
  top: 48px;
`;

export const MobileProgressBarDeal = styled(ProgressBar)`
  width: 80px;
  height: 4px;

  background-color: rgba(${Theme.colors.Brand}, 0.1);

  & div::before,
  & div::after {
    background-color: rgba(${Theme.colors.Brand}, 0.5);

    height: 4px;
  }

  @media ${MediaQuery.SCREEN_MD_UP} {
    display: none;
  }
`;
