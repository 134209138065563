import { Product } from '@/domain/entities/Product';
import { Category } from './Category';
import { Comment } from './Comment';
import { Image } from './Image';
import { Store } from './Store';
import { Vote } from './Vote';
import { Community, CommunityGenderPreference } from './Communities';

export type OfferId = string;

export const enum OfferKind {
  COUPON = 'COUPON',
  PROMOTION = 'PROMOTION',
}

export const enum OfferStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  EXPIRED = 'EXPIRED',
  MODERATED = 'MODERATED',
  PENDING = 'PENDING',
  SCHEDULED = 'SCHEDULED',
}

export type OfferQueryPublicStoreImage = {
  id: string;
  url?: string;
  small: string;
  medium: string;
  large: string;
  extraLarge: string;
  original: string;
};

export type DealTip = {
  title: string;
  description: string;
  smallIconUrl: string;
};

export type DealDeletionReasons = {
  id: string;
  title: string;
  link: string;
  bannerTitle: string | null;
  description: string | null;
};

export type DealTimestamps = {
  firstApprovedAt: string | null;
  approvedAt: string | null;
  createdAt: string;
  pickedAt: string | null;
  lastCommentedAt: string | null;
  publishAt: string | null;
};

export type DealCategory = {
  id: string;
  slug: string;
  title: string;
  communities: Community[];
};

export type DealUserImage = {
  id: string;
  url: string;
};

export type DealUser = {
  id: string;
  image: DealUserImage | null;
  nickname: string;
  isVerified: boolean;
};

export type DealStore = {
  id: string;
  name: string;
  slug: string;
  url: string;
  image: Image;
};

export type DealAssociatedProduct = Pick<
  Product,
  | 'title'
  | 'id'
  | 'rangePercentage90Days'
  | 'currentMinPrice'
  | 'rangePercentageMessage'
>;

export type Deal = {
  id: string;
  body: string | null;
  noIndex: boolean;
  commentCount: number;
  couponCode: string | null;
  discountFixed: number | null;
  discountPercentage: number | null;
  freeShipping: boolean | null;
  image?: Image;
  images?: Image[];
  isLocal: boolean | null;
  isFirstOffer: boolean;
  isPicked: boolean | null;
  isTracked: boolean;
  hideInFeed: boolean | null;
  expireLock: boolean | null;
  lockedComments: boolean;
  kind: OfferKind;
  price: number | null;
  status: OfferStatus;
  temperature: number;
  title: string;
  sourceUrl: string | null;
  tip: DealTip | null;
  deletionReasons: DealDeletionReasons[] | null;
  timestamps: DealTimestamps;
  category: DealCategory | null;
  user: DealUser;
  store: DealStore | null;
  featuredComment?: Comment;
  lastComment?: Comment;
  isSaved: boolean;
  productId?: string;
  associatedProduct?: DealAssociatedProduct;
  genderPreference?: CommunityGenderPreference;
  autoGeneratedDescription?: string;
};

export type OfferQueryPublic = {
  offer: Deal;
};

export type OfferQueryResponse = {
  public: OfferQueryPublic;
};

export type ProfileOffer = {
  id: OfferId;
  title: string;
  status: OfferStatus;
  price: number;
  discountFixed?: number;
  discountPercentage?: number;
  freeShipping?: boolean;
  store?: Store;
  featuredComment?: Comment;
  couponCode?: string;
  kind: OfferKind;
  image?: {
    id: string;
    url: string;
  };
  temperature: number;
  commentCount: number;
  category?: Category;
  user: DealUser;
  timestamps: {
    createdAt: string;
    firstApprovedAt?: string;
  };
  voteInfo?: Vote;
};

export type OfferVotes = [
  {
    id: string;
    voted: boolean;
    kind?: string;
  }
];

export type DealVote = {
  id: string;
  voted: boolean;
  kind?: string;
};
export type FeedDeal = Omit<Deal, 'featuredComment' | 'keyword'> & {
  voteInfo?: Vote;
  isSaved: boolean;
};

export type DealWithVote = Deal & {
  voteInfo?: Vote;
};

export type FeaturedOffer = Omit<FeedDeal, 'lastComment'> & {
  recommendationId?: string;
};

export type RecentOffer = Omit<FeedDeal, 'lastComment'>;

export type HottestFeedOffer = Omit<FeedDeal, 'lastComment'>;

export type CommentedOffer = FeedDeal & Pick<Deal, 'lastComment'>;

export type CommunityOffer = Omit<FeedDeal, 'lastComment'>;

export type SavedDeal = Omit<FeedDeal, 'lastComment'>;

export type DuplicatedDeal = Pick<Deal, 'id' | 'title' | 'kind' | 'status'>;

export type ProductCommunityDeal = Pick<
  Deal,
  'id' | 'image' | 'title' | 'price' | 'sourceUrl' | 'store' | 'couponCode'
>;

export type ProductExternalDeal = Pick<
  Deal,
  'id' | 'image' | 'title' | 'store'
> & {
  priceString?: string;
  url: string;
  ammountOfParcels?: number;
  parcelValue?: number;
  ean?: string;
};

export type OfferOptions = {
  recommendationId?: string;
};

export type ProductAssociatedDeal = Pick<
  Deal,
  | 'id'
  | 'kind'
  | 'status'
  | 'title'
  | 'temperature'
  | 'price'
  | 'discountFixed'
  | 'discountPercentage'
  | 'freeShipping'
  | 'commentCount'
  | 'timestamps'
  | 'store'
> & { image: Image & { preview: string } };
