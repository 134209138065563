import { Colors } from '@pelando/components';
import styled from 'styled-components';
import EmptyState from '../../../../../components/EmptyState';

export const FeedEndScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > div {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
`;

export const FeedEndScreenInformation = styled(EmptyState)`
  flex-grow: 1;
  margin-top: 72px;

  & > i {
    color: rgb(${Colors.Alien});
    font-size: 47px;
  }
`;
